import {
  Box,
  Button,
  Group,
  JsonInput,
  LoadingOverlay,
} from "@mantine/core";
import { IconCheck, IconX } from "@tabler/icons-react";
import { useDisclosure } from "@mantine/hooks";
import { useForm } from "@mantine/form";
import { useEffect } from "react";
import { NotificationExtension } from "../../../src/_base/extension/NotificationExtension";
import { isNullOrUndefined } from "../../../src/_base/extension/StringExtension";
import { editPage } from "../../../src/api/ApiPage";
import { API_ROUTE } from "../../../src/const/apiRoute";
import { MessageResponse } from "../../../src/model/MessageResponse";
import { tblPegaEdit } from "../../../src/model/Page";
import { AxiosResponse } from "axios";
import { api } from "../../library/axios";
import { modals } from "@mantine/modals";

const EditView = ({ id, onSearch }: EditViewProps) => {
  const entity: tblPegaEdit = {
    id: 0,
    pageName: null,
    content: null,
  };

  const handleEditCustomerAppointment = async (dataSubmit: tblPegaEdit) => {
    open();
    try {
      const response = await editPage(dataSubmit);
      if (response && response.success) {
        modals.closeAll();
        onSearch();
      } else {
        NotificationExtension.Fails("Chỉnh sửa thất bại hoặc phản hồi không như mong đợi.");
      }
    } catch (error) {
      NotificationExtension.Fails("Đã xảy ra lỗi trong khi chỉnh sửa.");
    } finally {
      close();
    }
  };

  const [visible, { close, open }] = useDisclosure(false);

  const form = useForm<tblPegaEdit>({
    initialValues: {
      ...entity,
    },
  });
  

  const getDataMediaGroup = async (id: number) => {
    try {
      const response: AxiosResponse<MessageResponse<tblPegaEdit>> = await api.get(
        `${API_ROUTE.GET_LIST_PAGE}?id=${id}`
        
      );
      if (response.data && !isNullOrUndefined(response.data)) {
        const dataApi: tblPegaEdit = response.data.data; // Lấy dữ liệu cụ thể từ phản hồi
        if (dataApi) {
          form.setValues(dataApi); // Chỉ truyền dữ liệu cụ thể vào setValues
        } else {
          NotificationExtension.Fails("Dữ liệu không tồn tại.");
          modals.closeAll();
        }
      } else {
        NotificationExtension.Fails("Dữ liệu không tồn tại.");
        modals.closeAll();
      }
    } catch (error) {
      NotificationExtension.Fails("Lỗi khi lấy dữ liệu.");
    } finally {
      close();
    }
  };

  useEffect(() => {
    if (id) {
      open();
      getDataMediaGroup(id);
    }
  }, [id]);

  return (
    <Box
      className="flex-none"
      component="form"
      miw={800}
      maw={800}
      mx="auto"
      onSubmit={form.onSubmit((e: tblPegaEdit) => {
        handleEditCustomerAppointment(e);
      })}
    >
      <LoadingOverlay
        visible={visible}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
      />
      <JsonInput
        label="Text Cần Sửa"
        placeholder="Nhập Text cần sửa ...."
        validationError="Invalid JSON"
        formatOnBlur
        autosize
        minRows={5}
        {...form.getInputProps("content")}
      />
      <Group justify="flex-end" mt="lg">
        <Button
          type="submit"
          color="#3598dc"
          loading={visible}
          leftSection={<IconCheck size={18} />}
        >
          Sửa
        </Button>
        <Button
          variant="outline"
          color="black"
          type="button"
          loading={visible}
          onClick={() => modals.closeAll()}
          leftSection={<IconX size={18} />}
        >
          Đóng
        </Button>
      </Group>
    </Box>
  );
};

export default EditView;

type EditViewProps = {
  id: number;
  onSearch: () => void;
};
