import { Editor } from "@tinymce/tinymce-react";
import { uploadImage } from "../../api/ApiProduct";
import { Box, LoadingOverlay } from "@mantine/core";
import { useDebouncedCallback, useDisclosure } from "@mantine/hooks";

const TinyMCEEditor = ({
  contentText,
  setValue,
}: {
  contentText: string;
  setValue: (value: any) => void;
}) => {
  const [visible, { toggle, open, close }] = useDisclosure(false);

  const handleEditorChange = useDebouncedCallback((content: string) => {
    setValue(content);
  }, 300);

  const handleImageUpload = async (
    image: File,
    success: (url: string) => void,
    failure: (message: string) => void
  ) => {
    try {
      open();
      const response = await uploadImage([
        {
          Image: image,
          TableName: "tblItem",
        },
      ]);
      close();

      if (response.data) {
        success(response.data);
      } else {
        throw new Error("Failed to convert URL to image file");
      }
    } catch (error) {
      failure("An error occurred during image upload");
    }
  };

  return (
    <Box>
      <LoadingOverlay
        visible={visible}
        zIndex={9000}
        overlayProps={{ radius: "sm", blur: 2 }}
      />
      <Editor
        apiKey="wwheiwaj492tbs9774t0z89v05r3xt8eg1ljilyc5d784xsv"
        initialValue={contentText}
        init={{
          height: 500,
          menubar: true,
          plugins: [
            "advlist autolink lists link image charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code help wordcount",
            "table",
            "image",
            "wordcount",
            "link",
            "media",
          ],
          toolbar:
            "undo redo | blocks | formatselect | bold italic backcolor | link | \
            alignleft aligncenter alignright alignjustify | wordcount | \
            bullist numlist outdent indent | removeformat | help | image | media | \
            table",
          image_title: true,
          automatic_uploads: true,
          file_picker_types: "image",
          file_picker_callback: (callback, value, meta) => {
            if (meta.filetype === "image") {
              const input = document.createElement("input");
              input.setAttribute("type", "file");
              input.setAttribute("accept", "image/*");
              input.addEventListener("change", function () {
                const fileList = this.files;
                if (fileList && fileList.length > 0) {
                  const file = fileList[0];
                  handleImageUpload(
                    file,
                    (url) => callback(url, { alt: "My alt text" }),
                    (message) => console.error(message)
                  );
                }
              });
              input.click();
            }
          },
        }}
        onEditorChange={(e) => {
          handleEditorChange(e);
        }}
      />
    </Box>
  );
};

export default TinyMCEEditor;
