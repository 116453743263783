import {
  Box,
  Button,
  Group,
  JsonInput,
  LoadingOverlay,
  TextInput,
} from "@mantine/core";
import { isNotEmpty, useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { IconCheck, IconX } from "@tabler/icons-react";
import { Pega } from "../../model/Page";
import { createPegaHtml } from "../../api/ApiPage";



const CreateView = ({ onSearch }: any) => {
  const entity = {
    id: 0,
pageName: null,
  content: null,

    // id:0,
    // pageName: null,
    // request_id: 0,

  };

  const handleCreateConfigGroup = async (dataSubmit: Pega) => {
    open();
    const response = await createPegaHtml(dataSubmit);
    if (response.success) {
      modals.closeAll();
    }
    onSearch();
    close();
  };

  const [visible, { toggle, close, open }] = useDisclosure(false);

  const form = useForm<Pega>({
    initialValues: {
      ...entity,
    },
    validate: {
     
    },
  });

  return (
    <>
       <Box
        className="flex-none"
        component="form"
        miw={800}
        maw={800}
        mx="auto"
        onSubmit={form.onSubmit((e: Pega) => {
          handleCreateConfigGroup(e);
        })}
      >
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />

        <TextInput
          label={"Tên Footer"}
          placeholder={"Nhập tên Footer cần sửa"}
          withAsterisk
          mt="md"
          type="text"
          {...form.getInputProps("pageName")}
        />
        <JsonInput
          label="Text Cần Sửa"
          placeholder="Nhập Text cần sửa ...."
          validationError="Invalid JSON"
          formatOnBlur
          autosize
          minRows={5}
          {...form.getInputProps("content")}
        />

        <Group justify="flex-end" mt="lg">
          <Button
            type="submit"
            color="#3598dc"
            loading={visible}
            leftSection={<IconCheck size={18} />}
          >
            Lưu
          </Button>
          <Button
            variant="outline"
            color="black"
            type="button"
            loading={visible}
            onClick={() => modals.closeAll()}
            leftSection={<IconX size={18} />}
          >
            Đóng
          </Button>
        </Group>
      </Box>
    </>
  );
};

export default CreateView;
