import {
  Box,
  Button,
  FileInput,
  Group,
  LoadingOverlay,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { IconCheck, IconUpload, IconX } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { NotificationExtension } from "../../../../_base/extension/NotificationExtension";
import { isNullOrUndefined } from "../../../../_base/extension/StringExtension";
import Repository from "../../../../_base/helper/HttpHelper";
import { Updatefilexcel } from "../../../../api/ApiAddress";
import { MessageResponse } from "../../../../model/MessageResponse";

const UpdateExel = ({ id, onSearch }: any) => {
  const entity = {
    FormFile: "", // Đổi từ "" thành null
  };

  const [file, setFile] = useState();

  const handleCreateProvince = async (dataSubmit: DataProvince) => {
    const formData = new FormData();
    console.log("11111111111", dataSubmit.FormFile);
    console.log("222222222", dataSubmit.FormFile?.File);

    // Kiểm tra nếu FormFile không phải là null trước khi thêm vào formData
    if (dataSubmit.FormFile) {
      formData.append("FormFile", dataSubmit.FormFile); // thêm file vào FormData
    } else {
      NotificationExtension.Fails("Vui lòng chọn một file để cập nhật."); // Thêm thông báo nếu không có file
      return; // Dừng lại nếu không có file
    }
    const response = await Updatefilexcel(formData);

    if (response?.success) {
      modals.closeAll();
      NotificationExtension.Success("Cập nhật thành công"); // Thêm thông báo thành công
    } else {
      NotificationExtension.Fails("Cập nhật thất bại"); // Thêm thông báo thất bại
    }

    onSearch();
  };

  const repository = new Repository(process.env.REACT_APP_Demo_APP_API_URL);
  const [visible, { close, open }] = useDisclosure(false);

  const form = useForm<DataProvince>({
    initialValues: {
      ...entity,
    },
    validate: {
      FormFile: (value) => (value ? null : "Vui lòng chọn một file"),
    },
  });

  useEffect(() => {
    if (id) {
      callApiGetData();
    }
  }, [id]);

  const callApiGetData = async () => {
    open();
    const urlDetail = `/TblItem/update-excel`;
    let callApi = await repository.post<MessageResponse<DataProvince>>(
      urlDetail
    );
    if (!isNullOrUndefined(callApi) && !isNullOrUndefined(callApi?.data)) {
      const dataApi = callApi?.data;
      if (dataApi != null && !isNullOrUndefined(dataApi)) {
        form.setValues(dataApi);
      } else {
        NotificationExtension.Fails("Dữ liệu không tồn tại");
        modals.closeAll();
      }
      close();
    } else {
      NotificationExtension.Fails("Dữ liệu không tồn tại");
      modals.closeAll();
    }
  };

  const handleFileChange = (file: any) => {
    // Xử lý tệp được chọn (nếu cần)
    setFile(file); // Bạn có thể thay đổi hành động này theo yêu cầu của bạn
  };

  return (
    <Box
      className="flex-none"
      component="form"
      miw={600}
      maw={600}
      mx="auto"
      onSubmit={form.onSubmit((e: DataProvince) => {
        handleCreateProvince(e);
      })}
    >
      <LoadingOverlay
        visible={visible}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
      />

      <Group grow wrap="nowrap" mt="0" gap={"lg"}>
        <FileInput
          label="Nhập File"
          description="Upload Excel files (.xls, .xlsx)"
          placeholder="Select a file"
          mt="md"
          accept=".xls,.xlsx"
          {...form.getInputProps("FormFile")}
          rightSection={<IconUpload />}
        />
      </Group>
      <Group justify="flex-end" mt="lg">
        <Button
          type="submit"
          color="#3598dc"
          loading={visible}
          leftSection={<IconCheck size={18} />}
        >
          Cập Nhật
        </Button>
        <Button
          variant="outline"
          color="black"
          type="button"
          loading={visible}
          onClick={() => modals.closeAll()}
          leftSection={<IconX size={18} />}
        >
          Đóng
        </Button>
      </Group>
    </Box>
  );
};

type DataProvince = {
  FormFile: any; // Thay đổi kiểu thành File | null
};

export default UpdateExel;
