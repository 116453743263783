import { EuiHealth, EuiIcon, EuiLink, EuiTreeView } from "@elastic/eui";
import { Flex, LoadingOverlay } from "@mantine/core";
import { useEffect, useState } from "react";
import { NotificationExtension } from "../../_base/extension/NotificationExtension";
import {
  isNullOrUndefined,
  isNullOrUndefinedArry,
} from "../../_base/extension/StringExtension";
import { getDataTree } from "../../api/ApiMegaMenu";
import { BASE_WEB_SERVICE } from "../../config";
import style from "./home.module.css";
import "./style.css";
import { useDisclosure } from "@mantine/hooks";

const TreeView = () => {
  const [items, setItems] = useState([]);
  const [visible, { toggle, open, close }] = useDisclosure(false);

  const buildTreeItems = (categories: any[]): any => {
    return categories?.map((category: any) => ({
      label: (
        <Flex
          pos={"relative"}
          align={"center"}
          justify={"space-between"}
          w={"100%"}
          gap={"10"}
        >
          <div
            className={
              category?.categoryChildModels?.length > 0
                ? style.expanded
                : style.name
            }
          >
            {category?.categoryName}
          </div>
          <div className={style.viewPage}>
            |{" "}
            <EuiLink
              target="_blank"
              href={`${BASE_WEB_SERVICE}/danh-muc/${category?.description}`}
            >
              Xem trang
            </EuiLink>
          </div>
          <div className={style.visibleType}>
            | {renderVisibleType(category)}
          </div>
          <div className={style.idCate}>| {category?.id}</div>
        </Flex>
      ),
      id: `category_${category.id}`,
      icon: <EuiIcon type="folderClosed" />,
      iconWhenExpanded: <EuiIcon type="folderOpen" />,
      children:
        category?.categoryChildModels?.length > 0
          ? buildTreeItems(category?.categoryChildModels)
          : undefined,
    }));
  };

  const renderVisibleType = (category: any) => {
    const color = category?.status === "I" ? "success" : "danger";
    return (
      <EuiHealth color={color}>
        {" "}
        {category?.status === "I" ? "Hạ xuống" : "Hiển thị"}{" "}
      </EuiHealth>
    );
  };

  const fetchDataTree = async () => {
    try {
      open();
      const callapi = await getDataTree();
      if (
        isNullOrUndefined(callapi?.data) ||
        isNullOrUndefinedArry(callapi?.data)
      ) {
        NotificationExtension.Fails("Không có dữ liệu !");
        close();
        return;
      }
      setItems(callapi?.data);
      close();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchDataTree();
  }, []);

  return (
    <div style={{ width: "800px", height: "fit-content" }}>
      <LoadingOverlay
        visible={visible}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
      />
      <table className={style.treeTable}>
        <tr className={style.header}>
          <td>Danh mục</td>
          <td>Link web</td>
          <td>Trạng thái</td>
          <td>ID</td>
        </tr>
        <tr>
          <td colSpan={4}>
            <EuiTreeView
              items={buildTreeItems(items)}
              aria-label="Sample Folder Tree"
              style={{
                width: "100%",
              }}
            />
          </td>
        </tr>
      </table>
    </div>
  );
};

export default TreeView;
