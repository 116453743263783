import {
  CriteriaWithPagination,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  Pagination,
} from "@elastic/eui";
import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  Select,
  TextInput,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { paginationBase } from "../../../src/_base/model/_base/BaseTable";
import { NotificationExtension } from "../../_base/extension/NotificationExtension";
import {
  dataSynchronization,
  dataSynchronizationAll,
  dataSynchronizationSearch,
} from "../../api/ApiDataSynchronization";
import { ReactSpreadsheetImport } from "react-spreadsheet-import";
import * as xlsx from "xlsx";
import {
  IconFileDownload,
  IconPlus,
  IconRefresh,
  IconSearch,
  IconTablePlus,
} from "@tabler/icons-react";

const DataSynchronization = () => {
  const columns: Array<EuiBasicTableColumn<any>> = [
    {
      field: "sku",
      name: "Mã SKU",
      sortable: true,
      width: "30%",
      truncateText: true,
    },
    {
      field: "type",
      name: "Loại",
      sortable: true,
      width: "30%",
      truncateText: true,
    },

    {
      name: "Actions",
      width: "30%",
      render: (name: any["sku"]) => {
        return (
          <>
            <EuiFlexGroup
              responsive={true}
              wrap={false}
              gutterSize="s"
              alignItems="center"
            >
              <EuiFlexItem grow={false}>
                <EuiButtonIcon
                  iconType="trash"
                  aria-label="Dashboard"
                  color="danger"
                  onClick={() => {
                    const newData = datas.filter(
                      (item) => item.sku !== name.sku
                    );
                    setDatas(newData);
                  }}
                />
              </EuiFlexItem>
            </EuiFlexGroup>
          </>
        );
      },
    },
  ];

  const fields = [
    {
      label: "SKU",
      key: "sku",
      fieldType: {
        type: "input",
      },
      example: "PIND869",
      validations: [
        {
          rule: "required",
          errorMessage: "Vui lòng nhập mã SKU",
          level: "error",
        },
      ],
    },
    {
      label: "Loại",
      key: "type",
      fieldType: {
        type: "input",
      },
      example: "buyandrepair",

      validations: [
        {
          rule: "required",
          errorMessage: "Vui lòng chọn loại",
          level: "error",
        },
      ],
    },
  ] as const;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [pagination, setPagination] = useState<Pagination>(paginationBase);
  const [datas, setDatas] = useState<any[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [item, setItem] = useState<{
    sku: string;
    type: string;
  }>({
    sku: "",
    type: "",
  });

  const gridSpan = { base: 12, xs: 6, sm: 6, md: 4, lg: 2, xl: 1.5 };

  const onTableChange = async ({
    page: { index, size },
  }: CriteriaWithPagination<any>) => {
    setPagination({
      ...pagination,
      pageIndex: index,
      pageSize: size,
      totalItemCount: 1000,
    });
  };

  const addItem = () => {
    if (!item.sku || !item.type) {
      NotificationExtension.Warn("Vui lòng nhập mã SKU và chọn kiểu");
      return;
    }
    setDatas((prev) => [...prev, item]);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const onImport = (data: any) => {
    const dataImported = data?.all?.map((item: any) => ({
      sku: item?.sku,
      type: item?.type,
    }));
    setDatas((prev) => [...prev, ...dataImported]);
  };

  const submit = async () => {
    if (datas.length > 0) {
      const dataSubmit = {
        datas: datas,
      };
      const response = await dataSynchronization(dataSubmit);
      setDatas([]);
    } else {
      NotificationExtension.Warn("Vui lòng thêm một vài sản phẩm!");
    }
  };

  const synchronous = async () => {
    await dataSynchronizationAll();
  };

  const synchronousSearch = async () => {
    await dataSynchronizationSearch();
  };

  const handleExport = () => {
    try {
      const dataExport = [
        {
          SKU: "MAU001",
          Loại: "buy",
        },
        {
          SKU: "MAU002",
          Loại: "repair",
        },
        {
          SKU: "MAU003",
          Loại: "buyandrepair",
        },
      ];
      const worksheet = xlsx.utils.json_to_sheet(dataExport);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, "Data");
      xlsx.writeFile(workbook, "mau.xlsx");
      NotificationExtension.Success("Tải tệp excel thành công");
    } catch (error) {
      NotificationExtension.Fails("Tải tệp excel thất bại");
    }
  };

  return (
    <>
      <Box>
        <Flex w={"100%"} gap={10}>
          <Grid w={"100%"} align="end">
            <Grid.Col span={gridSpan}>
              <TextInput
                withAsterisk
                label="Mã SKU"
                placeholder="Nhập mã SKU"
                onChange={(e) =>
                  setItem((prev) => ({ ...prev, sku: e.target.value ?? "" }))
                }
              ></TextInput>
            </Grid.Col>
            <Grid.Col span={gridSpan}>
              <Select
                withAsterisk
                clearable
                data={[
                  {
                    label: "Mua và sửa chữa",
                    value: "buyandrepair",
                  },
                  {
                    label: "Sửa chữa",
                    value: "repair",
                  },
                  {
                    label: "Mua",
                    value: "buy",
                  },
                ]}
                label="Chọn kiểu"
                placeholder="Chọn kiểu"
                onChange={(e) =>
                  setItem((prev) => ({ ...prev, type: e ?? "" }))
                }
              ></Select>
            </Grid.Col>
            <Grid.Col span={gridSpan}>
              <Button
                w={"100%"}
                leftSection={<IconPlus size={"14px"}></IconPlus>}
                onClick={() => addItem()}
              >
                Thêm sản phẩm
              </Button>
            </Grid.Col>
            <Grid.Col span={gridSpan}>
              <Button
                w={"100%"}
                leftSection={<IconTablePlus size={"14px"}></IconTablePlus>}
                color="teal"
                variant="light"
                onClick={() => setIsOpen(true)}
              >
                Tải lên Excel
              </Button>
            </Grid.Col>
            <Grid.Col span={gridSpan}>
              <Button
                w={"100%"}
                leftSection={
                  <IconFileDownload size={"14px"}></IconFileDownload>
                }
                color="violet"
                variant="light"
                onClick={() => handleExport()}
              >
                Tải mẫu Excel
              </Button>
            </Grid.Col>
            <Grid.Col span={gridSpan}>
              <Button
                w={"100%"}
                leftSection={<IconRefresh size={"14px"}></IconRefresh>}
                color="teal"
                onClick={() => submit()}
              >
                Đồng bộ từ bảng
              </Button>
            </Grid.Col>
            <Grid.Col span={gridSpan}>
              <Button
                w={"100%"}
                leftSection={<IconRefresh size={"14px"}></IconRefresh>}
                color="grape"
                onClick={() => synchronous()}
              >
                Đồng bộ giá
              </Button>
            </Grid.Col>
            <Grid.Col span={gridSpan}>
              <Button
                w={"100%"}
                leftSection={<IconSearch size={"14px"}></IconSearch>}
                color="orange"
                onClick={() => synchronousSearch()}
              >
                Đồng bộ tìm kiếm
              </Button>
            </Grid.Col>
          </Grid>

          <ReactSpreadsheetImport
            translations={{
              uploadStep: {
                title: "Tải lên tệp excel",
                manifestTitle: "Dự liệu mong đợi:",
                manifestDescription:
                  "(Bạn sẽ có cơ hội đổi tên hoặc xóa cột trong các bước tiếp theo)",
                dropzone: {
                  title: "Tải lên tệp có định dạng .xlsx, .xls hoặc .csv ",
                  errorToastDescription: "Tải lên bị từ chối",
                  activeDropzoneTitle: "Thả tệp ở đây...",
                  buttonTitle: "Chọn tệp",
                  loadingTitle: "Đang xử lý...",
                },
                selectSheet: {
                  title: "Chọn trang tính để sử dụng",
                  nextButtonTitle: "Tiếp tục",
                  backButtonTitle: "Quay lại",
                },
              },
              selectHeaderStep: {
                title: "Chọn hàng tiêu đề",
                nextButtonTitle: "Tiếp theo",
                backButtonTitle: "Quay lại",
              },
              matchColumnsStep: {
                title: "Khớp cột",
                nextButtonTitle: "Tiếp theo",
                backButtonTitle: "Quay lại",
                userTableTitle: "Bảng của bạn",
                templateTitle: "Sẽ trở thành",
                selectPlaceholder: "Chọn cột...",
                ignoredColumnText: "Cột bị bỏ qua",
                subSelectPlaceholder: "Chọn...",
                matchDropdownTitle: "Khớp",
                unmatched: "Không khớp",
                duplicateColumnWarningTitle: "Một cột khác chưa được chọn",
                duplicateColumnWarningDescription:
                  "Các cột không thể trùng lặp",
              },
              validationStep: {
                title: "Xác thực dữ liệu",
                nextButtonTitle: "Xác nhận",
                backButtonTitle: "Quay lại",
                noRowsMessage: "Không tìm thấy dữ liệu",
                noRowsMessageWhenFiltered: "Không có dữ liệu chứa lỗi",
                discardButtonTitle: "Loại bỏ hàng đã chọn",
                filterSwitchTitle: "Chỉ hiển thị các hàng có lỗi",
              },
              alerts: {
                confirmClose: {
                  headerTitle: "Thoát quy trình nhập liệu",
                  bodyText:
                    "Bạn có chắc chắn? Thông tin hiện tại của bạn sẽ không được lưu.",
                  cancelButtonTitle: "Hủy bỏ",
                  exitButtonTitle: "Thoát quy trình",
                },
                submitIncomplete: {
                  headerTitle: "Phát hiện lỗi",
                  bodyText:
                    "Vẫn còn một số hàng chứa lỗi. Các hàng chứa lỗi sẽ bị bỏ qua khi gửi.",
                  bodyTextSubmitForbidden: "Vẫn còn một số hàng chứa lỗi.",
                  cancelButtonTitle: "Hủy bỏ",
                  finishButtonTitle: "Gửi",
                },
                submitError: {
                  title: "Lỗi",
                  defaultMessage: "Đã xảy ra lỗi khi gửi dữ liệu",
                },
                unmatchedRequiredFields: {
                  headerTitle: "Không phải tất cả các cột đều được khớp",
                  bodyText:
                    "Có các cột bắt buộc chưa được khớp hoặc bị bỏ qua. Bạn có muốn tiếp tục?",
                  listTitle: "Các cột chưa khớp:",
                  cancelButtonTitle: "Hủy bỏ",
                  continueButtonTitle: "Tiếp tục",
                },
                toast: {
                  error: "Lỗi",
                },
              },
            }}
            isOpen={isOpen}
            onClose={onClose}
            onSubmit={(data) => onImport(data)}
            fields={fields}
          />
        </Flex>
      </Box>
      <Divider my="sm" />
      <EuiSpacer size="l" />
      <EuiBasicTable
        tableCaption="Demo of EuiDataGrid with selection"
        items={datas ? datas : []}
        itemId="id"
        error={error}
        loading={loading}
        noItemsMessage={"Không có dữ liệu"}
        columns={columns}
        pagination={pagination}
        isSelectable={true}
        hasActions={true}
        responsive={true}
        onChange={onTableChange}
        compressed={true}
      />
    </>
  );
};

export default DataSynchronization;
