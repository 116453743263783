import { AxiosResponse } from "axios";
import { api } from "../library/axios";
import { API_ROUTE } from "../const/apiRoute";
import { HanderResponse } from "../_base/helper/FunctionHelper";
import { NotificationExtension } from "../_base/extension/NotificationExtension";
import { isNullOrUndefined } from "../_base/extension/StringExtension";

export const dataSynchronization = async (data: any): Promise<any> => {
  try {
    const response: AxiosResponse = await api.post(
      API_ROUTE.DATA_SYNCHRONIZATION,
      data
    );
    if (!isNullOrUndefined(response) && response?.data?.success) {
      NotificationExtension.Success("Đồng bộ thành công !");
    } else if (response != null)
      NotificationExtension.Fails("Đồng bộ thất bại !");

    return response.data;
  } catch (error) {
    HanderResponse(error);
  }
};

export const dataSynchronizationAll = async (): Promise<any> => {
  try {
    const response: AxiosResponse = await api.get(
      API_ROUTE.DATA_SYNCHRONIZATION_ALL
    );
    if (!isNullOrUndefined(response) && response?.data?.success) {
      NotificationExtension.Success("Đồng bộ giá thành công !");
    } else if (response != null)
      NotificationExtension.Fails("Đồng bộ giá thất bại !");

    return response.data;
  } catch (error) {
    HanderResponse(error);
  }
};

export const dataSynchronizationSearch = async (): Promise<any> => {
  try {
    const response: AxiosResponse = await api.get(
      API_ROUTE.DATA_SYNCHRONIZATION_SEARCH
    );
    if (response) {
      NotificationExtension.Success("Đồng bộ tìm kiếm thành công !");
    } else if (response != null)
      NotificationExtension.Fails("Đồng bộ tìm kiếm thất bại !");

    return response.data;
  } catch (error) {
    HanderResponse(error);
  }
};
